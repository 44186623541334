import * as React from 'react'
import { Div } from 'glamorous'

import { mobile } from 'shared/media-queries'

const defaults = {
	fontSize: '38px',
	fontWeight: '700',
	lineHeight: '1.11',
}

const SectionHeader = props => {
	const css = {
		...defaults,
		[mobile]: {
			fontSize: '20px',
			lineHeight: '1.2',
		},
		...props.additionalCss,
	}
	return <Div {...props} css={css} />
}

export default SectionHeader
