// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { Button } from 'views/components'
import { navy, cyan } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'
import { tablet, mobile } from 'shared/media-queries'
import SectionHeader from '../components/section-header'
import Laptop from './laptop-image'

const BookDemo = () => (
	<Div
		display="flex"
		css={{ [mobile]: { display: 'block' } }}
		justifyContent="center"
	>
		<Div
			flex="0 0 50%"
			overflow="hidden"
			css={{ [mobile]: { textAlign: 'center' } }}
		>
			<Laptop />
		</Div>
		<Div flex="0 0 50%" css={{ [mobile]: { textAlign: 'center' } }}>
			<SectionHeader
				color={navy}
				maxWidth="385px"
				css={{
					[tablet]: { fontSize: '36px' },
					[mobile]: {
						margin: '0 auto',
					},
				}}
			>
				Don't get left behind—get started with Bid Board Pro today.
			</SectionHeader>
			<Button
				marginTop="30px"
				label="Get a demo"
				backgroundColor={cyan}
				css={{ [mobile]: { width: '260px' } }}
				onClick={() => showRequestDemoModal()}
			/>
		</Div>
	</Div>
)

export default BookDemo
