import * as React from 'react'
import { Button } from 'views/components'
import { cyan, navy } from 'shared/colors'
import { mobile } from 'shared/media-queries'
import { countryCodes } from 'shared/get-country'

const usCopy = {
	header: {
		heading: 'GCs want you to bid with BuildingConnected',
		subheading:
			'Get started with the Essential Bidding Toolkit and supercharge your bidding with Bid Board Pro—everything you need to win over GCs.',
		ctas: {
			button1Label: 'Supercharge with Bid Board Pro',
			button2Label: 'See what’s in the toolkit',
		},
	},
	footer: {
		wording: 'Everything you need to win the bids you want.',
		subtext:
			'Get the Essential Bidding Toolkit now and check out Bid Board Pro with your team for free.',
		buttonWording: 'Get a demo of Bid Board Pro',
		ctas: [
			<Button
				label="Get the bidding checklist"
				key="Get the bidding checklist"
				onClick={() => {
					window.open(
						'https://buildingconnected.zendesk.com/hc/en-us/articles/360021503993',
						'_blank'
					)
				}}
				backgroundColor={navy}
				borderRadius="2px"
				border={`1px solid ${cyan}`}
				padding="0px 20px"
				marginLeft="15px"
				cursor="pointer"
				css={{
					[mobile]: { marginTop: '10px', marginLeft: '0px' },
				}}
			/>,
		],
	},
	win: {
		sectionHeaderText:
			'The win-win bidding solution for subcontractors and GCs.',
		sectionHeaderSubtext:
			'Get on the same page as GCs and bid to win with BuildingConnected.',
		sectionInfo: {
			award: {
				title:
					'Don’t get lost in the shuffle: Easier GC bid reviews means more wins for you.',
				content:
					"It's so easy to recreate bid packages and send them out to all subs with BuildingConnected. You can easily look up a similar project and know what subs actually responded to those invites.",
			},
			handshake: {
				title:
					'Speak their language: Direct messages to strengthen GC relationships.',
				content:
					"Our relationships with subs are a lot more streamlined with BuildingConnected. It's nice having one way to get in contact with them. Subs said it's a lot easier than other methods they used before.",
			},
			snap: {
				title: 'Make life easier for everyone with a unified bid process.',
				content:
					'After a 3-minute call with subs explaining how it works, they would say, ‘Wow, this is amazing.’ Now the process is a well-oiled machine. More and more subs are using it, and it’s made the bidding process much smoother for us too.',
			},
			group: {
				title: 'Beat out the competition with the new preconstruction process.',
				content:
					"BuildingConnected is becoming the standard for the distribution and leveling of bids for GCs. Subs are noticing they should get on board since they're competing with other qualified subs already on it. It makes our lives more convenient when subs are on BuildingConnected.",
			},
		},
	},
	unbox: {
		sectionHeader: 'What’s inside the Essential Bidding Toolkit.',
		description:
			'Easy-to-access tools and tips to set yourself up for success with BuildingConnected.',
		sectionInfo: [
			{
				title: 'HOW-TO VIDEO',
				subtitle:
					'What you need to know, from receiving ITBs to submitting proposals.',
				wistiaId: 'rh9560qz56',
			},
			{
				title: 'THE BIDDING GUIDE',
				subtitle: 'The step-by-step list to successful bidding.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-us/articles/360021503993',
			},
			{
				title: 'HOW TO GET A QUICK BID REVIEW',
				subtitle: 'The cheat sheet to getting your bids reviewed faster.',
				url:
					'https://drive.google.com/file/d/16ECW4IA-QiGxAgd9DR-6j6ApMIMt7MGD/view?usp=sharing',
			},
			{
				title: 'HOW TO SET UP AN AWESOME PROFILE',
				subtitle: 'Complete your profile so you can win the work you want.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-us/articles/360022339953',
			},
			{
				title: 'SEE WHAT YOU CAN DO WITH BID BOARD PRO',
				subtitle: 'Dive into Bid Board Pro.',
				url:
					'https://drive.google.com/file/d/1BIS4AlZYAC2Ijs3D_DzlcQpwdPF6F58M/view',
			},
		],
	},
}

const anzCopy = {
	header: {
		heading: 'Get started with the Tendering Toolkit for BuildingConnected.',
		subheading: 'Everything you need to win over Head Contractors',
		ctas: {
			button1Label: 'Create account',
			button1Href: 'https://app.buildingconnected.com/create-account',
			button2Label: 'See what’s in the toolkit',
		},
	},
	footer: {
		wording: 'Everything you need to tender.',
		subtext:
			'Get the Essential Tendering Toolkit and start tendering on BuildingConnected.',
		ctas: [
			<Button
				label="Get the bidding checklist"
				key="Get the bidding checklist"
				onClick={() => {
					window.open(
						'https://buildingconnected.zendesk.com/hc/en-au/articles/360021503993',
						'_blank'
					)
				}}
				backgroundColor={navy}
				borderRadius="2px"
				border={`1px solid ${cyan}`}
				padding="0px 20px"
				marginLeft="15px"
				cursor="pointer"
				css={{
					[mobile]: { marginTop: '10px', marginLeft: '0px' },
				}}
			/>,
		],
	},
	win: {
		sectionHeaderText:
			'The win-win tendering solutions for Subcontractors and Head Contractors',
		sectionHeaderSubtext:
			'Get on the same page as Head Contractors and win through BuildingConnected.',
		sectionInfo: {
			award: {
				title:
					'Don’t get lost in the shuffle: Easier Head Contractor reviews means more wins for you.',
				content:
					"It's so easy to create tender packages and send them out to all subs with BuildingConnected. You can easily look up a similar project and know what subs actually respond to those invites.",
			},
			handshake: {
				title:
					'Speak their language: Direct messages to strengthen relationships',
				content:
					"Our relationships with subs are a lot more streamlined with BuildingConnected. It's nice having one way to get in contact with them. Subs said it's a lot easier than other methods they used before.",
			},
			snap: {
				title: 'Make life easier for everyone with a unified tender process.',
				content:
					'After a 3-minute call with subs explaining how it works, they would say, “Wow, this is amazing.” Now the process is a well-oiled machine. More and more subs are using it, and it’s made the tendering process much smoother for us too.',
			},
			group: {
				title: 'Beat out the competition with the new preconstruction process.',
				content:
					'BuildingConnected is becoming the standard for the distribution and leveling of tenders for Head Contractors. Subs are noticing they should get on board since they’re competing with other qualified subs already on it. It makes our lives more convenient when subs are on BuildingConnected.',
			},
		},
	},
	unbox: {
		sectionHeader: 'What’s inside the Essential Tendering Toolkit.',
		description:
			'Easy-to-access tools and tips to set yourself up for success with BuildingConnected.',
		sectionInfo: [
			{
				title: 'HOW-TO VIDEO',
				subtitle:
					'What you need to know, from receiving invitations to tender to submitting quotes.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-au/articles/360010016754-Online-training-Tender-comparison',
			},
			{
				title: 'THE TENDERING GUIDE',
				subtitle: 'The step-by-step list to successful tendering.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-au/articles/360021503993',
			},
			{
				title: 'HOW TO GET A QUICK TENDER REVIEW',
				subtitle: 'The cheat sheet to getting your tenders reviewed faster.',
				url:
					'http://constructioncloud.autodesk.com/rs/572-JSV-775/images/submit-your-tenders-bc.pdf',
			},
			{
				title: 'HOW TO SET UP AN AWESOME PROFILE',
				subtitle:
					'Complete your profile so you get invited to the right projects.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-au/articles/360022339953',
			},
		],
	},
}

const ukCopy = {
	...anzCopy,
	header: {
		...anzCopy.header,
		subheading: 'Everything you need to win over Main Contractors',
	},
	win: {
		...anzCopy.win,
		sectionHeaderText:
			'The win-win tendering solutions for Subcontractors and Main Contractors',
		sectionHeaderSubtext:
			'Get on the same page as Main Contractors and win through BuildingConnected.',
		sectionInfo: {
			...anzCopy.win.sectionInfo,
			award: {
				...anzCopy.win.sectionInfo.award,
				title:
					'Don’t get lost in the shuffle: Easier Main Contractor reviews means more wins for you.',
			},
			group: {
				...anzCopy.win.sectionInfo.group,
				content:
					'BuildingConnected is becoming the standard for the distribution and leveling of tenders for Main Contractors. Subs are noticing they should get on board since they’re competing with other qualified subs already on it. It makes our lives more convenient when subs are on BuildingConnected.',
			},
		},
	},
	unbox: {
		...anzCopy.unbox,
		sectionInfo: [
			{
				title: 'HOW-TO VIDEO',
				subtitle:
					'What you need to know, from receiving invitations to tender to submitting quotes.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-uk/articles/360010016754-Online-training-Tender-comparison',
			},
			{
				title: 'THE TENDERING GUIDE',
				subtitle: 'The step-by-step list to successful tendering.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-uk/articles/360021503993',
			},
			{
				title: 'HOW TO GET A QUICK TENDER REVIEW',
				subtitle: 'The cheat sheet to getting your tenders reviewed faster.',
				url:
					'http://constructioncloud.autodesk.com/rs/572-JSV-775/images/Submit-your-tenders-BuildingConnected.pdf',
			},
			{
				title: 'HOW TO SET UP AN AWESOME PROFILE',
				subtitle:
					'Complete your profile so you get invited to the right projects.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-ie/articles/360022339953-Complete-your-BuildingConnected-profile-and-get-invited-to-the-projects-you-want-to-tender-on',
			},
		],
	},
}

const ieCopy = {
	...ukCopy,
	unbox: {
		...ukCopy.unbox,
		sectionInfo: [
			{
				title: 'HOW-TO VIDEO',
				subtitle:
					'What you need to know, from receiving invitations to tender to submitting quotes.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-ie/articles/360010016754-Online-training-Tender-comparison',
			},
			{
				title: 'THE TENDERING GUIDE',
				subtitle: 'The step-by-step list to successful tendering.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-uk/articles/360021503993',
			},
			{
				title: 'HOW TO GET A QUICK TENDER REVIEW',
				subtitle: 'The cheat sheet to getting your tenders reviewed faster.',
				url:
					'http://constructioncloud.autodesk.com/rs/572-JSV-775/images/Submit-your-tenders-BuildingConnected.pdf',
			},
			{
				title: 'HOW TO SET UP AN AWESOME PROFILE',
				subtitle:
					'Complete your profile so you get invited to the right projects.',
				url:
					'https://buildingconnected.zendesk.com/hc/en-ie/articles/360022339953-Complete-your-BuildingConnected-profile-and-get-invited-to-the-projects-you-want-to-tender-on',
			},
		],
	},
}

const copy = {
	[countryCodes.us]: usCopy,
	[countryCodes.au]: anzCopy,
	[countryCodes.nz]: anzCopy,
	[countryCodes.uk]: ukCopy,
	[countryCodes.ie]: ieCopy,
}

const content = country => copy[country]

export default content
