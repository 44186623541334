export const list1 = [
	'Unify your bid board with all your invites—not just the ones from BuildingConnected',
	'Track all your bids from start to finish',
	'Never miss due dates or job walks with a shared bid calendar',
]

export const list2 = [
	'Discover more GCs bidding on the same projects',
	'Save estimators time with easier workflows',
]
