// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { grayText, navy } from 'shared/colors'
import { laptop, tablet, mobile } from 'shared/media-queries'
import { HangingQuote } from 'views/components'

type SectionInfo = {
	icon: React.Node,
	title: React.Node,
	content: string,
	reviewerName: string,
	reviewerDetails: string,
}

const ReviewBlock = ({
	icon,
	title,
	content,
	reviewerName,
	reviewerDetails,
}: SectionInfo) => (
	<Div
		flex="0 0 46%"
		margin="15px 15px 35px"
		css={{
			[tablet]: {
				flex: '1 1 0%',
			},
		}}
	>
		<Div height="74" width="74">
			{icon}
		</Div>
		<Div
			paddingTop="10px"
			fontSize="20px"
			color={navy}
			fontWeight="bold"
			css={{
				[tablet]: { textAlign: 'left' },
			}}
		>
			{title}
		</Div>
		<Div
			fontSize="18px"
			color={grayText}
			paddingTop="26px"
			paddingBottom="20px"
			css={{
				[laptop]: { paddingTop: '20px' },
				[mobile]: { paddingTop: '10px' },
			}}
		>
			<HangingQuote>{content}</HangingQuote>
		</Div>
		<Div color={grayText} fontSize="18px">
			<Div fontWeight="bold">{reviewerName}</Div>
			<Div>{reviewerDetails}</Div>
		</Div>
	</Div>
)

export default ReviewBlock
