// @flow
import React, { useRef } from 'react'
import _ from 'lodash'
import ReactDOM from 'react-dom'
import { Div } from 'glamorous'
import withCountry, { isUSA } from 'shared/get-country'
import Layout from 'components/layout'
import {
	SectionSpacer,
	SectionBreak,
	ContentWrapper,
	Footer,
} from 'views/components'
import { cyan, navy } from 'shared/colors'
import Header from './header'
import WinWin from './win-win'
import WhatsInTheBox from './whats-inside'
import BookDemo from './book-a-demo'
import { tablet, mobile } from 'shared/media-queries'
import Supercharge from './supercharge'
import { Button } from 'views/components'
import useScreenWidth from 'hooks/use-screen-width'
import generateContent from './content'

const scrollToSection = ref => {
	const node = ReactDOM.findDOMNode(ref.current)
	if (node instanceof HTMLElement) {
		const top = node.offsetTop - 85
		window.scrollTo(0, top)
	}
}

const BidBoardProToolkit = ({ country }) => {
	const screenWidth = useScreenWidth()
	const whatsInsideRef = useRef(null)
	const superchargeRef = useRef(null)
	const content = generateContent(country)
	if (!country) return null
	const { header, footer, win, unbox } = content

	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Bidding Toolkit for Subcontractors | BuildingConnected"
			description="Win more of the projects you want by bidding the way GCs want you to – online. It's free, and with these resources, it's simpler than ever before."
		>
			<Header
				screenWidth={screenWidth}
				headerText={header.heading}
				subheaderText={header.subheading}
				CTAs={
					<Div
						flex="1 1 0%"
						display="flex"
						css={{
							[tablet]: { justifyContent: 'center' },
							[mobile]: { flexDirection: 'column' },
						}}
					>
						<Button
							label={header.ctas.button1Label}
							backgroundColor={cyan}
							onClick={() => {
								const href = _.get(header, 'ctas.button1Href')
								if (href) {
									window.open(href, '_blank')
								} else {
									scrollToSection(superchargeRef)
								}
							}}
							backgroundColor={cyan}
							padding="0px 20px"
							cursor="pointer"
							css={{ [mobile]: { padding: '0 15px' } }}
						/>
						<Div flex="0 0 15px" />
						<Button
							label={header.ctas.button2Label}
							backgroundColor={navy}
							borderRadius="2px"
							border={`1px solid ${cyan}`}
							padding="0px 20px"
							cursor="pointer"
							onClick={() => {
								scrollToSection(whatsInsideRef)
							}}
						/>
					</Div>
				}
			/>
			<SectionSpacer />
			<ContentWrapper>
				<WinWin {...win} country={country} />
				<SectionBreak />
				<WhatsInTheBox forwardedRef={whatsInsideRef} {...unbox} />
				{isUSA(country) && <SectionBreak />}
				{isUSA(country) && <Supercharge forwardedRef={superchargeRef} />}
				<SectionSpacer />
			</ContentWrapper>
			{isUSA(country) && <BookDemo />}
			{isUSA(country) && <SectionSpacer />}
			<Footer
				wording={footer.wording}
				subtext={footer.subtext}
				demoButtonWording={footer.buttonWording}
				demoButtonPadding="20px !important"
				showCreateAccount
				noDemo={!isUSA(country)}
				additionalCTAs={footer.ctas}
			/>
		</Layout>
	)
}

export default withCountry(BidBoardProToolkit)
