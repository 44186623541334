// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { grayText, navy } from 'shared/colors'
import sInfo from './section-info'
import { tablet, mobile } from 'shared/media-queries'
import SectionHeader from '../components/section-header'
import ReviewBlock from './review-block'

const WinWin = ({ sectionHeaderText, sectionHeaderSubtext, sectionInfo }) => (
	<Div alignItems="center" display="flex" flexDirection="column">
		<SectionHeader textAlign="center" color={navy} maxWidth="600px">
			{sectionHeaderText}
		</SectionHeader>
		<Div height="20px" />
		<Div
			textAlign="center"
			fontSize="22px"
			color={grayText}
			css={{
				[mobile]: { fontSize: '16px' },
			}}
		>
			{sectionHeaderSubtext}
		</Div>
		<Div
			height="35px"
			css={{
				[tablet]: {
					display: 'none',
				},
			}}
		/>
		<Div
			display="flex"
			flexWrap="wrap"
			width="100%"
			justifyContent="center"
			marginBottom="-35px" // Account for review block margin bottom
			css={{
				[tablet]: {
					flexDirection: 'column',
				},
			}}
		>
			{sInfo(sectionInfo).map(item => (
				<ReviewBlock key={item.reviewerName} {...item} />
			))}
		</Div>
	</Div>
)

export default WinWin
