// @flow
import * as React from 'react'
import { Img } from 'glamorous'
import { laptop, tablet, mobile } from 'shared/media-queries'

import laptopImg from './img/laptop.png'
import laptopImg2x from './img/laptop@2x.png'
import laptopImg3x from './img/laptop@3x.png'

const Image = () => (
	<Img
		width="auto"
		src={laptopImg}
		srcSet={`${laptopImg2x} 2x, ${laptopImg3x} 3x`}
		float="right"
		height="434px"
		css={{
			[laptop]: { height: '385px' },
			[tablet]: { height: '317px' },
			[mobile]: {
				height: 'auto',
				float: 'none',
				width: '100%',
				maxWidth: '450px',
			},
		}}
	/>
)

export default Image
