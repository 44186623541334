// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import Award from 'icons/award.svg'
import Handshake from 'icons/hand-shake.svg'
import FingerGun from 'icons/finger-gun.svg'
import GroupLeader from 'icons/group-leader.svg'

import { tablet } from 'shared/media-queries'

const sInfo = ({ award, handshake, snap, group }) => {
	return [
		{
			icon: <Award />,
			title: (
				<Div maxWidth="485px" css={{ [tablet]: { maxWidth: 'none' } }}>
					{award.title}
				</Div>
			),
			content: award.content,
			reviewerName: 'Kenji Frahm',
			reviewerDetails: 'Project Engineer, BCCI',
		},
		{
			icon: <Handshake />,
			title: (
				<Div maxWidth="485px" css={{ [tablet]: { maxWidth: 'none' } }}>
					{handshake.title}
				</Div>
			),
			content: handshake.content,
			reviewerName: 'Theresa McArdle',
			reviewerDetails: 'Senior Cost Engineer, Truebeck',
		},
		{
			icon: <FingerGun />,
			title: snap.title,
			content: snap.content,
			reviewerName: 'Stephen Fontana',
			reviewerDetails: 'Estimator, Holt Construction',
		},
		{
			icon: <GroupLeader />,
			title: group.title,
			content: group.content,
			reviewerName: 'Stanley Abellard',
			reviewerDetails: 'Estimator, Plaza Construction',
		},
	]
}

export default sInfo
