// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { grayText, navy } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'
import ToolkitContent from './toolkit-content'
import SectionHeader from '../components/section-header'

// type RefProps = {
// 	forwardedRef: React.Ref<'div'>,
// }

const WhatsInTheBox = ({
	forwardedRef,
	sectionHeader,
	description,
	sectionInfo,
}) => (
	<Div
		ref={forwardedRef}
		display="flex"
		css={{ [tablet]: { flexDirection: 'column' } }}
	>
		<Div flex="1 1 0%">
			<SectionHeader
				color={navy}
				css={{
					[tablet]: {
						textAlign: 'center',
						maxWidth: '510px',
						margin: '0 auto',
					},
				}}
			>
				{sectionHeader}
			</SectionHeader>
			<Div height="20px" />
			<Div
				fontSize="22px"
				color={grayText}
				fontWeight="300"
				css={{
					[tablet]: {
						textAlign: 'center',
						maxWidth: '430px',
						margin: '0 auto',
					},
					[mobile]: {
						fontSize: '16px',
					},
				}}
			>
				{description}
			</Div>
		</Div>
		<Div
			flex="0 0 20px"
			css={{
				[tablet]: {
					flex: '0 0 40px',
				},
			}}
		/>
		<Div
			display="flex"
			flexDirection="column"
			flex="2 2 0%"
			borderBottom="2px solid #f1f1f1"
		>
			{sectionInfo.map((info, i) => (
				<ToolkitContent key={i} {...info} />
			))}
		</Div>
	</Div>
)

export default WhatsInTheBox
