// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { Link } from 'gatsby'

import { grayText, navy, cyan, brandBlue } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'
import { BulletList } from 'views/components'
import SectionHeader from '../components/section-header'
import { list1, list2 } from './section-info'

type RefProps = {
	forwardedRef: React.Ref<'div'>,
}

const Supercharge = ({ forwardedRef }: RefProps) => (
	<Div
		display="flex"
		flexDirection="column"
		alignItems="center"
		ref={forwardedRef}
	>
		<SectionHeader
			textAlign="center"
			color={navy}
			maxWidth="800px"
			css={{ [tablet]: { maxWidth: '515px' }, [mobile]: { maxWidth: '273px' } }}
		>
			Supercharge your free version of BuildingConnected with Bid Board Pro.
		</SectionHeader>
		<Div
			textAlign="center"
			fontSize="22px"
			color={grayText}
			marginTop="20px"
			css={{
				[tablet]: { marginBottom: '35px', maxWidth: '430px' },
				[mobile]: { marginTop: '10px', fontSize: '16px' },
			}}
		>
			Subcontractors using Bid Board Pro have increased their win rates by up to
			25%.
		</Div>
		<Div
			height="50px"
			css={{
				[tablet]: { display: 'none' },
			}}
		/>
		<Div
			display="flex"
			justifyContent="center"
			css={{
				[tablet]: { display: 'block', maxWidth: '510px' },
			}}
		>
			<Div
				flex="0 0 383px"
				css={{
					[tablet]: {
						display: 'flex',
						flex: '0 0 510px',
						justifyContent: 'center',
					},
				}}
			>
				<BulletList
					dotColor={cyan}
					items={list1}
					boldItems
					boldItemsColor={navy}
				/>
			</Div>
			<Div
				display="flex"
				flexDirection="column"
				color={navy}
				flex="0 0 400px"
				css={{
					[tablet]: {
						flex: '0 0 510px',
						justifyContent: 'center',
					},
				}}
			>
				<BulletList
					dotColor={cyan}
					items={list2}
					boldItems
					boldItemsColor={navy}
				/>
				<Link
					css={{
						color: brandBlue,
						fontSize: '18px',
						fontWeight: 600,
						textDecoration: 'underline',
						marginLeft: '20px',
					}}
					to="/bid-board/"
				>
					Learn more about Bid Board Pro here
				</Link>
			</Div>
		</Div>
	</Div>
)

export default Supercharge
