// @flow
import * as React from 'react'
import { A, Div } from 'glamorous'
import { WistiaVideoOverlay } from 'views/components'
import { cyan, navy } from 'shared/colors'
import { mobile } from 'shared/media-queries'
import ArrowRight from 'icons/arrow-right.svg'

type SectionInfo = {
	title: string,
	subtitle: string,
	url: string,
	wistiaId: string,
}

// Control hover state css in this component since sometimes the children are an
// anchor tag and sometimes its a clickable div including a wistia wrapper, and
// we need them to display the same on hover, so we have to wrap them in this
// component a little differently
const HoverStateWrapper = ({ children }: { children: React.Node }) => (
	<Div
		color={navy}
		css={{
			'&:hover': { color: cyan, '& svg': { fill: cyan } },
		}}
	>
		{children}
	</Div>
)

const MainContent = ({ title, subtitle }) => (
	<Div
		borderTop="2px solid #f1f1f1"
		cursor="pointer"
		display="flex"
		justifyContent="space-between"
		alignItems="center"
	>
		<Div>
			<Div
				fontSize="20px"
				color="inherit"
				fontWeight="600"
				marginTop="17px"
				marginBottom="5px"
			>
				{title}
			</Div>
			<Div display="flex" color="inherit">
				<Div
					fontSize="20px"
					fontWeight="300"
					marginBottom="17px"
					css={{ [mobile]: { fontSize: '16px' } }}
				>
					{subtitle}
				</Div>
			</Div>
		</Div>
		<Div flex="0 0 25px" />
		<Div flex="0 0 25px">
			<ArrowRight fill={navy} />
		</Div>
	</Div>
)

const ToolkitContent = (info: SectionInfo) => {
	if (info.url) {
		return (
			<A target="_blank" href={info.url}>
				<HoverStateWrapper>
					<MainContent {...info} />
				</HoverStateWrapper>
			</A>
		)
	}
	return (
		<HoverStateWrapper>
			<Div position="relative">
				<MainContent {...info} />
				<WistiaVideoOverlay videoId={info.wistiaId} />
			</Div>
		</HoverStateWrapper>
	)
}

export default ToolkitContent
