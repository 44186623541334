// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import HeaderBackgroundImage from './background-image'
import { breakpoints, tablet, mobile } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.tablet) {
		return '590px'
	} else if (screenWidth > breakpoints.mobile) {
		return '350px'
	}
	return '290px'
}

const heading = text => (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		{text}
	</H1>
)

const subHeading = text => (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		{text}
	</Div>
)

const Header = ({
	screenWidth,
	CTAs,
	headerText,
	subheaderText,
}: {
	screenWidth: number,
	CTAs: React.Node,
	headerText: string,
	subheaderText: string,
}) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading(headerText)}
		subHeading={subHeading(subheaderText)}
		wordingWidth={getWordingWidth(screenWidth)}
		demoButtonWording="Supercharge with Bid Board Pro"
		CTAs={CTAs}
	/>
)

export default Header
